import styles from './FormTitle.module.css';

const FormTitle = ({title}) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.titlePrefix}></span>
      <span className={styles.title}>{title.toUpperCase()}</span>
    </div>
  ) 
}

export default FormTitle;

