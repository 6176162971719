// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailHeader_headerTitle__gZb8l {
  font-size: 58px;
  font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/emailHeader/EmailHeader.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".headerTitle {\n  font-size: 58px;\n  font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerTitle": `EmailHeader_headerTitle__gZb8l`
};
export default ___CSS_LOADER_EXPORT___;
