// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../assets/33ab07a3db260d2cd4d572b1c89f542a.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_container__Idy\\+t {
  min-height: 100vh;
  height: auto;
  /*background:url('./../../../assets/33ab07a3db260d2cd4d572b1c89f542a.png') no-repeat left 25% bottom 0, linear-gradient(to bottom, #b1e4f7, #97dcf3);*/
  background-position: left 0 top 0;
  background-size: cover;
  display: grid;
  grid-template-areas: "content contentBackground";
  grid-template-columns: 1fr 2fr;
}
.HomePage_contentContainer__hB69O {
  grid-area: "content";
  min-height:100vh;
  height: auto;
  /* max-width: 50vw;
  min-width:600px;
  width: 1200px; */
  background-color: #f0f1f3;
  display: grid;
  grid-template-areas: "header header header"
                       ". content .";
  grid-template-rows: 50px auto;
  grid-template-columns: minmax(0,25%) minmax(500px, 700px) auto;
}
.HomePage_contentBackground__Lr5yh {
  grid-area: "contentBackground";
  background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100%;
}
.HomePage_header__VW3hN {
  grid-area: header;
}
.HomePage_content__7AxuE {
  margin-top: 20%;
  grid-area: content;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/homePage_2/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,sJAAsJ;EACtJ,iCAAiC;EACjC,sBAAsB;EACtB,aAAa;EACb,gDAAgD;EAChD,8BAA8B;AAChC;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,YAAY;EACZ;;kBAEgB;EAChB,yBAAyB;EACzB,aAAa;EACb;oCACkC;EAClC,6BAA6B;EAC7B,8DAA8D;AAChE;AACA;EACE,8BAA8B;EAC9B,wDAA8E;EAC9E,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".container {\n  min-height: 100vh;\n  height: auto;\n  /*background:url('./../../../assets/33ab07a3db260d2cd4d572b1c89f542a.png') no-repeat left 25% bottom 0, linear-gradient(to bottom, #b1e4f7, #97dcf3);*/\n  background-position: left 0 top 0;\n  background-size: cover;\n  display: grid;\n  grid-template-areas: \"content contentBackground\";\n  grid-template-columns: 1fr 2fr;\n}\n.contentContainer {\n  grid-area: \"content\";\n  min-height:100vh;\n  height: auto;\n  /* max-width: 50vw;\n  min-width:600px;\n  width: 1200px; */\n  background-color: #f0f1f3;\n  display: grid;\n  grid-template-areas: \"header header header\"\n                       \". content .\";\n  grid-template-rows: 50px auto;\n  grid-template-columns: minmax(0,25%) minmax(500px, 700px) auto;\n}\n.contentBackground {\n  grid-area: \"contentBackground\";\n  background-image:url('./../../../assets/33ab07a3db260d2cd4d572b1c89f542a.png');\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  min-height: 100%;\n}\n.header {\n  grid-area: header;\n}\n.content {\n  margin-top: 20%;\n  grid-area: content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HomePage_container__Idy+t`,
	"contentContainer": `HomePage_contentContainer__hB69O`,
	"contentBackground": `HomePage_contentBackground__Lr5yh`,
	"header": `HomePage_header__VW3hN`,
	"content": `HomePage_content__7AxuE`
};
export default ___CSS_LOADER_EXPORT___;
