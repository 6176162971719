// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WarrantyForm_formHeaderWrapper__SEsOJ {
  display: flex;
  flex-direction: column;
  font-family: 'Quatro';
  color: #262626;
  margin-left:30px;
  margin-bottom: 20px;
}

.WarrantyForm_formContainer__iZBe1 {
  display: grid;
  grid-template-areas: "details details branch"
                       "display display branch"
                       ". error ."
                       ". button .";
  grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 0px 20px;
}
fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.WarrantyForm_details__8hVKs {
  grid-area: details;
}
.WarrantyForm_branch__JmXAR {
  grid-area: branch;
}
.WarrantyForm_branch__JmXAR > fieldset {
  width: 125px;
  margin-top: 20px;
  margin-left: 30px;
}
.WarrantyForm_branch__JmXAR > fieldset > label {
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap:20px;
}
.WarrantyForm_display__KSnmP {
  grid-area: display;
  margin: 10px 0 20px 0;
}
.WarrantyForm_button__Z-teV {
  grid-area: button;
  width: auto;
  justify-self: self-end;
}
.WarrantyForm_error__IdnLp {
  grid-area: error;
  color: #eb3232;
  font-size: 12px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/organisms/warrantyForm/WarrantyForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb;;;mCAGiC;EACjC,wDAAwD;EACxD,2DAA2D;EAC3D,aAAa;AACf;AACA;EACE,SAAS;EACT,UAAU;EACV,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".formHeaderWrapper {\n  display: flex;\n  flex-direction: column;\n  font-family: 'Quatro';\n  color: #262626;\n  margin-left:30px;\n  margin-bottom: 20px;\n}\n\n.formContainer {\n  display: grid;\n  grid-template-areas: \"details details branch\"\n                       \"display display branch\"\n                       \". error .\"\n                       \". button .\";\n  grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));\n  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));\n  gap: 0px 20px;\n}\nfieldset {\n  margin: 0;\n  padding: 0;\n  border: none;\n}\n.details {\n  grid-area: details;\n}\n.branch {\n  grid-area: branch;\n}\n.branch > fieldset {\n  width: 125px;\n  margin-top: 20px;\n  margin-left: 30px;\n}\n.branch > fieldset > label {\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  gap:20px;\n}\n.display {\n  grid-area: display;\n  margin: 10px 0 20px 0;\n}\n.button {\n  grid-area: button;\n  width: auto;\n  justify-self: self-end;\n}\n.error {\n  grid-area: error;\n  color: #eb3232;\n  font-size: 12px;\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formHeaderWrapper": `WarrantyForm_formHeaderWrapper__SEsOJ`,
	"formContainer": `WarrantyForm_formContainer__iZBe1`,
	"details": `WarrantyForm_details__8hVKs`,
	"branch": `WarrantyForm_branch__JmXAR`,
	"display": `WarrantyForm_display__KSnmP`,
	"button": `WarrantyForm_button__Z-teV`,
	"error": `WarrantyForm_error__IdnLp`
};
export default ___CSS_LOADER_EXPORT___;
