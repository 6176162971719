// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormInput_wrapper__amfzw {
  margin-top: 15px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0 20px;
}
.FormInput_titleContainer__RmpL8 {
  display:flex;
  flex-direction: column;
  font-family: 'Quatro';
}

.FormInput_title__uCNur {
  max-width: 102px;
  font-size: 14px;
  color: #262626;
}
.FormInput_subTitle__H4O1l {
  font-size: 9px;
  color: #666;
}
.FormInput_radioWrapper__BZWPZ {
  margin: 20px 25px;
  display: flex;
  flex-direction: column;
}
.FormInput_inputWrapper__3YqGa {
  display: flex;
  flex-direction: column;
  max-width:300px;
  align-items: end;
}
.FormInput_inputWrapper__3YqGa > label > input {
  width: 100%;
  min-width:190px;
  max-width:250px;
}
.FormInput_required__anV9d, .FormInput_error__KCrgp {
  color: #eb3232;
}
.FormInput_error__KCrgp {
  font-size: 11px;
  text-align: end;
}
.FormInput_radioWrapper__BZWPZ .FormInput_error__KCrgp {align-self: end;}
.FormInput_input__KDxbc.FormInput_error__KCrgp > input {
  border-color: #eb3232;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/formInput/FormInput.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;AACb;AACA;EACE,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,eAAe;EACf,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,eAAe;AACjB;AACA,wDAAsB,eAAe,CAAC;AACtC;EACE,qBAAqB;AACvB","sourcesContent":[".wrapper {\n  margin-top: 15px;\n  margin-left: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 0 20px;\n}\n.titleContainer {\n  display:flex;\n  flex-direction: column;\n  font-family: 'Quatro';\n}\n\n.title {\n  max-width: 102px;\n  font-size: 14px;\n  color: #262626;\n}\n.subTitle {\n  font-size: 9px;\n  color: #666;\n}\n.radioWrapper {\n  margin: 20px 25px;\n  display: flex;\n  flex-direction: column;\n}\n.inputWrapper {\n  display: flex;\n  flex-direction: column;\n  max-width:300px;\n  align-items: end;\n}\n.inputWrapper > label > input {\n  width: 100%;\n  min-width:190px;\n  max-width:250px;\n}\n.required, .error {\n  color: #eb3232;\n}\n.error {\n  font-size: 11px;\n  text-align: end;\n}\n.radioWrapper .error {align-self: end;}\n.input.error > input {\n  border-color: #eb3232;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FormInput_wrapper__amfzw`,
	"titleContainer": `FormInput_titleContainer__RmpL8`,
	"title": `FormInput_title__uCNur`,
	"subTitle": `FormInput_subTitle__H4O1l`,
	"radioWrapper": `FormInput_radioWrapper__BZWPZ`,
	"inputWrapper": `FormInput_inputWrapper__3YqGa`,
	"required": `FormInput_required__anV9d`,
	"error": `FormInput_error__KCrgp`,
	"input": `FormInput_input__KDxbc`
};
export default ___CSS_LOADER_EXPORT___;
