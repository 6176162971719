import styles from './ConfirmEmail.module.css';
import { ReactComponent as Reward } from "../../../../assets/reward.svg"
import EmailHeader from '../../atoms/emailHeader/EmailHeader';
import EmailSubHeader from '../../atoms/emailSubHeader/EmailSubHeader';
import EmailContent from '../../atoms/emailContent/EmailContent';

const ConfirmEmail = () => {
  return (
    <div className={styles.container}>
      <Reward className={styles.iconWrapper}>Icon</Reward>
      <EmailHeader />
      <EmailSubHeader />
      <EmailContent />
    </div>
  )
}

export default ConfirmEmail;