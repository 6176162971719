// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormSubHeader_formSubHeader__W9B0G {
  font-size: 23px;
  font-weight: 350;
  opacity:0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/formSubHeader/FormSubHeader.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".formSubHeader {\n  font-size: 23px;\n  font-weight: 350;\n  opacity:0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSubHeader": `FormSubHeader_formSubHeader__W9B0G`
};
export default ___CSS_LOADER_EXPORT___;
