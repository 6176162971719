import {CtouchLogo, CtouchLogoColor} from '@ctouch-europe-b-v/react-component-library';
import styles from './PageHeader.module.css'
import SupportButton from '../../atoms/supportButton/SupportButton';

const PageHeader = ({className, goHome}) => {
  return (
    <div className={[styles.container, className].filter(e => !!e).join(" ")} >
      <div className={styles.logoWrapper} onClick={() => goHome()}><CtouchLogo color={CtouchLogoColor.lifeAquatic} onClick={() => goHome} className={styles.ctouchLogo} /></div>
      <SupportButton className={styles.supportButton}/>
    </div>
  )
}

export default PageHeader;