import styles from './WarningMessage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WarningMessage = () => {
  return (
    <div className={styles.warning}>
      <FontAwesomeIcon icon={["fal","circle-info"]} />
      <span> Please ensure the email address is correct. We send the warranty details to this email address.</span>
    </div> 
  )
}
export default WarningMessage;