// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmEmail_container__Q88bi {
  font-family: 'Quatro', sans-serif;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  text-align: center;
}
/* .headerTitle {
  font-size: 58px;
  font-weight: 900;
}
.headerSubTitle {
  font-size: 23px;
  font-weight: 350;
}
.content {
  font-size: 14px;
  font-weight: 350;
} */`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/confirmEmail/ConfirmEmail.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,uBAAuB;EACvB,kBAAkB;AACpB;AACA;;;;;;;;;;;GAWG","sourcesContent":[".container {\n  font-family: 'Quatro', sans-serif;\n  height: 50vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  justify-content: center;\n  text-align: center;\n}\n/* .headerTitle {\n  font-size: 58px;\n  font-weight: 900;\n}\n.headerSubTitle {\n  font-size: 23px;\n  font-weight: 350;\n}\n.content {\n  font-size: 14px;\n  font-weight: 350;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ConfirmEmail_container__Q88bi`
};
export default ___CSS_LOADER_EXPORT___;
