// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormTitle_wrapper__Ci2Q- {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.FormTitle_titlePrefix__AWDRg {
  margin: 3px;
  width: 25px;
  border-bottom: 2px solid #262626;
}
.FormTitle_title__\\+rYah {
  font-family: 'Quatro';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 91%;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #262626;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/formTitle/FormTitle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,WAAW;EACX,WAAW;EACX,gCAAgC;AAClC;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n}\n.titlePrefix {\n  margin: 3px;\n  width: 25px;\n  border-bottom: 2px solid #262626;\n}\n.title {\n  font-family: 'Quatro';\n  font-style: normal;\n  font-weight: 700;\n  font-size: 10px;\n  line-height: 91%;\n  letter-spacing: 0.015em;\n  text-transform: uppercase;\n  color: #262626;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FormTitle_wrapper__Ci2Q-`,
	"titlePrefix": `FormTitle_titlePrefix__AWDRg`,
	"title": `FormTitle_title__+rYah`
};
export default ___CSS_LOADER_EXPORT___;
