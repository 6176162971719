// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelSubTitle_subTitle__k9V8K {
  font-size: 9px;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/labelSubTitle/LabelSubTitle.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;AACb","sourcesContent":[".subTitle {\n  font-size: 9px;\n  color: #666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subTitle": `LabelSubTitle_subTitle__k9V8K`
};
export default ___CSS_LOADER_EXPORT___;
