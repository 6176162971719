import { useContext } from 'react';
import FormInput from '../../atoms/formInput/FormInput';
import FormTitle from '../../atoms/formTitle/FormTitle';
import { FormContext } from '../../../../contexts/contexts';
import { RegisterService } from '../../../../service/RegisterService';
import WarningMessage from '../../atoms/warningMessage/WarningMessage';

const FormGroups = ({ setWarnings, title, className, hasWarnings, hasExtra }) => {
  const { form, setForm } = useContext(FormContext)

  const validateInput = async (type, e) => {
    const value = e.target.value
    const emailRegEx = /^\w+(?:[\w.-]*\w)?@(?!gmail|outlook|proton|hotmail|icloud|yahoo)\w+(?:[\w.-]*\w)?\.\w+$/
    let test;
    switch (type) {
      case "organisation":
        test = !(value.length < 250 && value.length > 0);
        test ? setWarnings(type, "Make sure your organisation name is longer that 0 and no longer than 250 characters") : setWarnings(type, undefined);
        break;
      case "email":
        test = !emailRegEx.test(value)
        test ? setWarnings(type, "Incorrect email format. We do not accept the following email domains: gmail, outlook, proton, hotmail, icloud, yahoo") : setWarnings(type, undefined);;
        if (form.email_repeat !== undefined && form.email_repeat.value !== '') {
          test = (value !== form.email_repeat.value)
          test ? setWarnings(type, "Emails do not match") : setWarnings(type, undefined);
        }
        break;
      case "email_repeat":
        test = !emailRegEx.test(form.email.value);
        if (emailRegEx.test(form.email.value)) {
          test = !(value === form.email.value);
          test ? setWarnings(type, "Emails do not match") : setWarnings(type, undefined);
        }
        break;
      case "serial":
        test = !/(55|65|75|86)((SPR|spr)([0-9A-Fa-f]{2}){5}|(X|x)(0(2RA|3CC|4CN|6DT|5RT))([0-9]{1})(0[1-9]|1[012])([A-Z]|[a-z]{1})([0-9]{4}))/.test(e.target.value)
        test ? setWarnings(type, "Incorrect serial format, check whether you have input the serial number correctly.") : setWarnings(type, undefined);
        if (value.length === 15) {
          await RegisterService.checkDuplicateSerial(value).then((response) => {
            test = !!response.message;
            test && setWarnings(type, "This serial number is already registered");
          })
        }
        break;
      case "mac":
        test = !/[0-9a-fA-F]{3}/.test(value);
        test ? setWarnings(type, "Incorrect mac format, check whether you have input the last three digits of your mainboard mac adress correctly") : setWarnings(type, undefined);;
        break;
      default:
        break;
    }
    setForm({ ...form, [type]: { value: value, valid: !test ? true : false } })
  }
  const renderFields = (type) => {
    let fields;
    switch (type) {
      case "Details":
        fields = [<FormInput hasError={hasWarnings?.organisation} type="organisation" value={form?.organisation?.value} key="organisation" title="Organisation" subTitle="Who owns the display" onBlur={(e) => validateInput("organisation", e)} onChange={(e) => validateInput("organisation", e)} />,
        <FormInput hasError={hasWarnings?.email} type="email" value={form?.email?.value} key="email" title="Email Adress" subTitle="Company email" onChange={(e) => validateInput("email", e)} onBlur={(e) => validateInput("email", e)} />,
        <FormInput hasError={hasWarnings?.email_repeat} type="email_repeat" value={form?.email_repeat?.value} key="email_repeat" title="Email Adress" subTitle="Repeat email" onBlur={(e) => validateInput("email_repeat", e)} onChange={(e) => validateInput("email_repeat", e)} />]
        break;
      case "Branch":
        fields = <FormInput hasError={hasWarnings?.branch} value={form?.branch?.value} isRadio={true} onBlur={(e) => validateInput("branch", e)} onChange={(object) => { setForm({ ...form, branch: object }); setWarnings("branch", null) }} />
        break;
      case "Display":
        fields = [<FormInput hasError={hasWarnings?.serial} type="serial" value={form?.serial?.value?.toUpperCase()} key="serial" title="Serial Number" subTitle="Of the display" placeholder="00X00XX000X0000" onBlur={(e) => validateInput("serial", e)} onChange={(e) => validateInput("serial", e)} />,
        <FormInput hasError={hasWarnings?.mac} type="mac" value={form?.mac?.value} key="mac" title="Mainboard Mac Address" subTitle="Last 3 digits only" placeholder="x x x" onBlur={(e) => validateInput("mac", e)} onChange={(e) => validateInput("mac", e)} />]
        break;
      default:
        break;
    }
    return fields;
  }

  return (
    <fieldset className={className}>
      <FormTitle title={title} />
      {renderFields(title)}
      {(hasExtra) &&
        <WarningMessage />
      }
    </fieldset>
  )
}

export default FormGroups;