import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

const errorHandler = (error) => {
    const statusCode = error.response?.status

    if (statusCode && statusCode !== 401) {
        console.error(error)
    }

    return Promise.reject(error)
}
api.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_JSON_TOKEN_API}`
    return config
})
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

export const AxiosService = {
    async getRequest(url, headers) {
        const response = await api.request({
            url: url,
            headers: headers && headers,
            method: "GET",
        });
        return response.data;
    },

    async postRequest(url, data, headers) {
        const response = await api.request({
            url: url,
            headers: headers && headers,
            method: "POST",
            data: data
        });
        return response.data;
    },
};