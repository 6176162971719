// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageHeader_logoWrapper__4-S0E {
  cursor: pointer;
}
.PageHeader_container__eBgn4{
  width: auto;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
}

.PageHeader_ctouchLogo__frUn5 {
  height: 26px;
  overflow: visible;
  padding-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/pageHeader/PageHeader.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".logoWrapper {\n  cursor: pointer;\n}\n.container{\n  width: auto;\n  padding: 15px 30px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.ctouchLogo {\n  height: 26px;\n  overflow: visible;\n  padding-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoWrapper": `PageHeader_logoWrapper__4-S0E`,
	"container": `PageHeader_container__eBgn4`,
	"ctouchLogo": `PageHeader_ctouchLogo__frUn5`
};
export default ___CSS_LOADER_EXPORT___;
