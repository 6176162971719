// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormSubmitButton_button__rL5X2 {
  display:flex;
  flex-direction:row-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/formSubmitButton/FormSubmitButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".button {\n  display:flex;\n  flex-direction:row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `FormSubmitButton_button__rL5X2`
};
export default ___CSS_LOADER_EXPORT___;
