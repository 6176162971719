import { useState } from 'react';
import { FormContext } from '../../../contexts/contexts';
import PageHeader from '../../ui/molecules/pageHeader/PageHeader';
import ConfirmEmail from '../../ui/molecules/confirmEmail/ConfirmEmail';
import WarrantyForm from '../../ui/organisms/warrantyForm/WarrantyForm';
import styles from './HomePage.module.css';

const formContextInit = {organisation:{value: "", valid: false},email: {value: "", valid:false}, email_repeat:{value: "", valid: false}, branch: {value: "", valid: false},serial: {value: "", valid: false},mac: {value: "", valid: false}}

const HomePage = () => {
  const [confirmEmailSend, setConfirmEmailSend] = useState(false);
  const [form, setForm] = useState(formContextInit)
  return (
    <div className={styles.container} > 
      <div className={styles.contentContainer}>
        <PageHeader className={styles.header} goHome={()=> {setConfirmEmailSend(false); setForm(formContextInit)} }/>
        <div className={styles.content} >
          {confirmEmailSend ? 
            <ConfirmEmail />
            :
            <FormContext.Provider value={{form, setForm}}>
              <WarrantyForm formIsSend={() => setConfirmEmailSend(true)} />
            </FormContext.Provider>
          }
        </div>
      </div>
      <div className={styles.contentBackground}>

      </div>
    </div>
  )
}

export default HomePage;