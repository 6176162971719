// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailSubHeader_headerSubTitle__WrbDk {
  font-size: 23px;
  font-weight: 350;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/emailSubHeader/EmailSubHeader.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".headerSubTitle {\n  font-size: 23px;\n  font-weight: 350;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerSubTitle": `EmailSubHeader_headerSubTitle__WrbDk`
};
export default ___CSS_LOADER_EXPORT___;
