import { AxiosService } from "./AxiosService";

const baseUrl = "warranty";

export const RegisterService = {
  async saveWarrantyDetails(formBody) {
    return await AxiosService.postRequest(`${baseUrl}/saveWarrantyDetails`, formBody)
  },
  async checkDuplicateSerial(serial) {
    return await AxiosService.getRequest(`${baseUrl}/${serial}/check_serial_exists`);
  } 
}