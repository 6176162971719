// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelTitle_title__\\+eb4\\+ {
  max-width: 102px;
  font-size: 14px;
  color: #262626;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/labelTitle/LabelTitle.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".title {\n  max-width: 102px;\n  font-size: 14px;\n  color: #262626;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `LabelTitle_title__+eb4+`
};
export default ___CSS_LOADER_EXPORT___;
