import LabelSubTitle from '../labelSubTitle/LabelSubTitle';
import LabelTitle from '../labelTitle/LabelTitle';
import styles from './FormInput.module.css';
import { Input, RadioGroup } from "@ctouch-europe-b-v/react-component-library";

const FormInput = ({title, type, subTitle, placeholder, isRadio, onChange, hasError, onBlur, value}) => {
  return (
     isRadio ? 
      <div className={styles.radioWrapper}>
      <RadioGroup className={styles.radio} onChange={(e) => onChange({value:e.target.value, valid: true})} options={["Education", "Business"]}/>
      {hasError && <span className={styles.error}>{hasError}</span>}
      </div>
      :
      <div className={styles.wrapper}>
        <label htmlFor={title.split(" ")[0]} className={styles.titleContainer}>
          <LabelTitle required={hasError !== undefined} title={title} />
          <LabelSubTitle subTitle={subTitle} />
        </label>
        <div className={styles.inputWrapper}>
        <Input className={[styles.input, hasError !== undefined && styles.error].filter(e => !!e).join(" ")} required={hasError !== undefined} id={title.split(" ")[0]} inputType="text" value={value} placeholder={placeholder} onChange={(e)=> onChange(e)} onBlur={(e) => onBlur(e)} />
        {hasError && <span className={styles.error}>{hasError}</span>}
        </div>
      </div>
  )
}

export default FormInput;