import styles from './WarrantyForm.module.css';
import FormGroups from '../../molecules/formGroups/FormGroups';
import FormSubmitButton from '../../atoms/formSubmitButton/FormSubmitButton';
import { FormContext } from '../../../../contexts/contexts';
import { useContext, useState } from 'react';
import { RegisterService } from '../../../../service/RegisterService';
import FormHeader from '../../atoms/formHeader/FormHeader';
import FormSubHeader from '../../atoms/formSubHeader/FormSubHeader';


const WarrantyForm = ({formIsSend}) => {
   const [warning, setWarning] = useState({})
  const {form} = useContext(FormContext)
  const onContinue = () => {
    let validBody = {};
    let invalidFields = [];
    for(const [field, fieldObject] of Object.entries(form)) {
      switch (field) {
        case "email":
          if(fieldObject.value !== "" && fieldObject.valid && form.email_repeat.valid) {validBody = {...validBody, [field]: fieldObject.value}}
          else {setWarning(prevWarning => prevWarning = {...prevWarning, [field]: "This field is incorrect. Personal mail domains are not allowed for registration"}); invalidFields.push(field)}
          break;
        case "organisation":
        case "serial":
        case "mac":
          if(fieldObject.value !== "" && fieldObject.valid) {validBody = {...validBody, [field]: fieldObject.value}}
          else {setWarning(prevWarning => prevWarning = {...prevWarning, [field]: "This field is incorrect"}); invalidFields.push(field)}
          break;
        case "branch": 
          if(fieldObject.value !== "" && fieldObject.valid) {validBody = {...validBody, [field]: fieldObject.value}}
          else {setWarning(prevWarning => prevWarning = {...prevWarning, [field]: "Select a branch"}); invalidFields.push(field)}
        break;
        case "email_repeat":
          if(fieldObject.value === '' || !fieldObject.valid) { setWarning(prevWarning => prevWarning = {...prevWarning, [field] : "This field is incorrect"})}
          break;
        default:
          setWarning(prevWarning => prevWarning = {...prevWarning, [field]: "This field is incorrect"}); invalidFields.push(field)
          break;
      }
    }
    if (invalidFields.length === 0) {
      RegisterService.saveWarrantyDetails(validBody)
      formIsSend();
    }
  }
  
  return (
    <>
      <div className={styles.formHeaderWrapper} >
        <FormHeader />
        <FormSubHeader />
      </div>

      <div className={styles.formContainer}> 
        <FormGroups className={styles.details} title="Details" hasExtra hasWarnings={warning} setWarnings={(type, warningText)=> setWarning({...warning, [type]:warningText})} />
        <FormGroups className={styles.branch} title="Branch" hasWarnings={warning} setWarnings={(type, warningText)=> setWarning({...warning, [type]:warningText})} />
        <FormGroups className={styles.display} title="Display" hasWarnings={warning} setWarnings={(type, warningText)=> setWarning({...warning, [type]:warningText})} />
        <FormSubmitButton className={styles.button} onClick={(e) => {e.stopPropagation();onContinue()}} />
      </div>
    </>
  )
}

export default WarrantyForm;